import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"



const Index = () => (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/XREAL_Beam_Pro_User_Manuals.pdf`}
    />
  </>
)

export default Index